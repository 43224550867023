@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

.comingsoon {
    font-family: 'Russo One', sans-serif;
    color: antiquewhite;
    font-size: 2em;
    position: absolute;
    z-index: 5;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.headie {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    height: auto;
    width: 200px;
}

.pic1 {
    top: 0%;
    left: -5px;
    height: auto;
    position: absolute;
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 1;
    width: 22%;
    animation: pic1 5s;
    animation-iteration-count: infinite;
    opacity: 0;
    animation-delay: 1s;

}

.pic2 {
    bottom: 0%;
    right: 0%;
    height: auto;
    position: absolute;
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 1;
    width: 22%;
    animation: pic2 7s;
    animation-iteration-count: infinite;
    opacity: 0;
    animation-delay: 3s;
}

.pic3 {
    bottom: 0%;
    left: 0%;
    height: auto;
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 1;
    width: 27%;
    animation: pic3 7s;
    animation-iteration-count: infinite;
    opacity: 0;
    animation-delay: 5s;
}

.pic4 {
    bottom: 0%;
    left: 0%;
    height: auto;
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 1;
    width: 27%;
    animation: pic4 9s;
    animation-iteration-count: infinite;
    opacity: 0;
    animation-delay: 1s;
}



@keyframes pic1 {
    0% {
        top: 0%;
        left: -5px;
        opacity: 1;
    }
    70% {
        left: 500px;
        opacity: 0;
    }
    100% {
        left: -5px;
        opacity: 0;
    }
}

@keyframes pic2 {
    0% {
        bottom: 0%;
        right: 0%;
        opacity: 1;
    }
    90% {
        bottom: 500px;
        right: 800px;
        opacity: 0;
    }
    100% {
        bottom: 0%;
        right: 0%;
        opacity: 0;
    }
}

@keyframes pic3 {
    0% {
        bottom: 0%;
        left: 0%;
        opacity: 1;
    }
    70% {
        bottom: 500px;
        left: 1000px;
        opacity: 0;
    }
    100% {
        bottom: 0%;
        left: 0%;
        opacity: 0;
    }
}

@keyframes pic4 {
    0% {
        bottom: 0%;
        left: 50%;
        opacity: 1;
       
    }
    70% {
        bottom: 500px;
        left: 50%;
        opacity: 0;
        transform: rotate(90deg)
    }
    100% {
        bottom: 0%;
        left: 0%;
        opacity: 0;
    }
}

@keyframes DVD {
    0% {
        left: 70%;
        top: 100%
    }
    5% {
        left: 100%;
        top: 100%
    }
    10% {
        left: 100%;
        top: 100%
    }
    15% {
        left: 100%;
        top: 100%
    }
    20% {
        left: 100%;
        top: 100%
    }
    25% {
        left: 100%;
        top: 100%
    }
    30% {
        left: 100%;
        top: 100%
    }
    35% {
        left: 100%;
        top: 100%
    }
    40% {
        left: 100%;
        top: 100%
    }
    45% {
        left: 100%;
        top: 100%
    }
    50% {
        left: 100%;
        top: 100%
        
    }
    55% {
        left: 100%;
        top: 100%
    }
    60% {
        left: 100%;
        top: 100%
    }
    65% {
        left: 100%;
        top: 100%
    }
    70% {
        left: 100%;
        top: 100%
    }
    75% {
        left: 100%;
        top: 100%
    }
    80% {
        left: 100%;
        top: 100%
    }
    85% {
        left: 100%;
        top: 100%
    }
    90% {
        left: 100%;
        top: 100%
    }
    95% {
        left: 100%;
        top: 100%
    }
    100% {
        left: 70%;
        top: 100%;
    }

}



